'use strict';

angular.module('risevision.common.components.userstate')
  .controller('AuthCommonCtrl', ['$scope', '$document',
    function ($scope, $document) {
      $scope.backgroundImage = '';
      $scope.init = function() {
        var rand = Math.random();
        $scope.backgroundImage = rand < 0.25 ? '/images/sign-up/room1.jpg'
          : rand < 0.5 ? '/images/sign-up/room2.jpg'
          : rand < 0.75 ? '/images/sign-up/room3.jpg'
          : '/images/sign-up/room4.jpg';
      };
      $scope.init();

      // Preload images
      var previewImages = [];
      previewImages[0] = new Image();
      previewImages[0].src = '/images/sign-up/preview1.jpg';
      previewImages[0].alt = 'Slide with New York weather forecast';
      previewImages[1] = new Image();
      previewImages[1].src = '/images/sign-up/preview2.jpg';
      previewImages[1].alt = 'Slide with employee spotlight';
      previewImages[2] = new Image();
      previewImages[2].src = '/images/sign-up/preview3.jpg';
      previewImages[2].alt = 'Slide with motivational text';

      var image = 1;
      function cycleImages() {
        var preview = document.getElementById('tv-preview');
        if (!document.images || !preview) {
          return;
        }
        preview.className = 'fade';
        setTimeout(function() {
          preview.src = previewImages[image].src;
          preview.alt = previewImages[image].alt;
          image += 1;
          preview.className = '';
          if (image > 2) {
            image = 0;
          }
        }, 500);
        setTimeout(cycleImages, 7000);
      }

      $document.ready(setTimeout(cycleImages, 7000));
    }
  ]);
