(function (angular) {
  'use strict';

  /*jshint camelcase: false */

  angular.module('risevision.common.components.userstate')
    .value('CLIENT_ID', '614513768474.apps.googleusercontent.com')
    .value('OAUTH2_SCOPES', 'email profile')

    .factory('openidConnectLoader', ['$q', '$window', 'localStorageService', 'userState',
      'CLIENT_ID', 'OAUTH2_SCOPES',
      function ($q, $window, localStorageService, userState, CLIENT_ID, OAUTH2_SCOPES) {
        if (!$window.Oidc || !localStorageService.isSupported) {
          return function () {
            return $q.reject('Oidc client not found!');
          };
        }

        var Oidc = $window.Oidc;

        Oidc.Log.logger = console;
        Oidc.Log.level = Oidc.Log.WARN;

        var service = {};
        var loc = $window.location.origin + '/';

        var settings = {
          authority: 'https://accounts.google.com/',
          client_id: CLIENT_ID,
          response_type: 'token id_token',
          scope: OAUTH2_SCOPES,
          prompt: 'select_account',
          redirect_uri: loc,
          post_logout_redirect_uri: loc + 'oidc-client-sample.html',

          silent_redirect_uri: loc + 'user-manager-silent.html',
          automaticSilentRenew: true,
          includeIdTokenInSilentRenew: false,

          filterProtocolClaims: true,
          loadUserInfo: true,

          userStore: new Oidc.WebStorageStateStore({
            store: $window.localStorage
          }),
          extraQueryParams: {
            access_type: 'online'
          }
        };
        var client = new Oidc.UserManager(settings);

        var _signinSilent = client.signinSilent.bind(client);

        client.signinSilent = function (params) {
          if (!params) {
            params = {
              login_hint: userState.getUsername()
            };
          }

          return _signinSilent(params);
        };

        return function () {
          return $q.resolve(client);
        };
      }
    ]);

})(angular);
