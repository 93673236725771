'use strict';

angular.module('risevision.common.components')
  .directive('colorPicker', [function () {
      return {
        restrict: 'E',
        scope: {
          ngModel: '=',
          ngChange: '&?',
          pickerId: '@?'
        },
        templateUrl: 'partials/components/color-picker/color-picker.html',
        link: function($scope) {
            $scope.$on('colorpicker-selected', $scope.ngChange);
        }
    };
}]);