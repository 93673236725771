'use strict';

angular.module('risevision.common.components')
  .directive('popupDatePicker', ['$timeout', 'outsideClickHandler',
    function ($timeout, outsideClickHandler) {

      return {
        restrict: 'E',
        scope: {
          ngModel: '=',
          ngChange: '&?'
        },
        templateUrl: 'partials/components/time-date/popup-date-picker.html',
        link: function ($scope) {
          $scope.isOpen = false;

          $scope.change = function() {
            if ($scope.ngChange) {
              $timeout($scope.ngChange);  
            }
          };

          $scope.openDatePicker = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.isOpen = !$scope.isOpen;
          };

          var _close = function() {
            $scope.isOpen = false;
          };

          $scope.$watch('isOpen', function (newValue) {
            if (newValue) {
              outsideClickHandler.bind('popup-date-picker', 
                '.counter-container [datepicker], .counter-container [datepicker-popup-wrap]', 
                _close);
            } else {
              outsideClickHandler.unbind('popup-date-picker');
            }
          });

        } //link()
      };
    }
  ]);
