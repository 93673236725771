(function (angular) {

  'use strict';

  angular.module('risevision.common.components.logging')
    .factory('analyticsFactory', ['$rootScope', '$window', '$log', '$location',
      function ($rootScope, $window, $log, $location) {
        var service = {};
        var loaded;

        $window.dataLayer = $window.dataLayer || [];

        service.track = function (eventName, properties) {
          if (typeof properties === 'string') {
            properties = {
              stringProperty: properties
            };
          }

          properties = properties || {};
          properties.category = 'apps';
          $window.dataLayer.push({
            event: 'analytics.track',
            eventName: eventName,
            analytics: {
              event: {
                properties: properties
              }
            }
          });
        };

        service.identify = function (userId, properties) {
          $window.dataLayer.push({
            event: 'analytics.identify',
            userId: userId,
            analytics: {
              user: {
                properties: properties
              }
            }
          });
        };

        service.page = function (properties) {
          properties = properties || {};
          properties.category = 'apps';
          $window.dataLayer.push({
            event: 'analytics.page',
            eventName: 'page viewed',
            analytics: {
              event: {
                properties: properties
              }
            }
          });
        };

        service.load = function (gtmContainerId, gtmAuth, gtmEnv) {
          if (gtmContainerId && !loaded) {

            //Google Tag Manager snippet
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
              });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              if (gtmAuth && gtmEnv) {
                j.src += '&gtm_auth=' + gtmAuth + '&gtm_preview=' + gtmEnv + '&gtm_cookies_win=x';
              }
              f.parentNode.insertBefore(j, f);
            })($window, $window.document, 'script', 'dataLayer', gtmContainerId);

            loaded = true;
            trackPageviews();
          }
        };

        function trackPageviews() {
          // Listening to $viewContentLoaded event to track pageview
          $rootScope.$on('$viewContentLoaded', function () {
            if (service.location !== $location.path()) {
              service.location = $location.path();
              var properties = {};
              properties.url = $location.path();
              properties.path = $location.path();
              if ($location.search().nooverride) {
                properties.referrer = '';
              }
              service.page(properties);
            }
          });
        }

        return service;
      }
    ])

    .factory('analyticsEvents', ['$window', '$rootScope', 'analyticsFactory',
      'userState', 'companyState', 'bigQueryLogging',
      function ($window, $rootScope, analyticsFactory, userState, companyState, bigQueryLogging) {
        var service = {};

		  // Appcues variables
		  var _loadAppcuesLaunchpad = function() {
          if (!$window.Appcues) {
            return;
          }

          $window.Appcues.loadLaunchpad('#appcues-launchpad', {
            //Optionally specify the position of the content relative to the Launchpad icon. Possible values are as followed:
            //	- center (default value, i.e. bottom-center)
            //	- left (i.e. bottom-left)
            //	- right (i.e. bottom-right)
            //	- top (i.e. top-center)
            //	- top-left
            //	- top-right
            position: 'left',
            // Optionally add a header or footer. This must be HTML.
            header: '<div><strong>Announcements</strong></div>',
            // footer: '<p>Your footer here</p>',
            // And if you'd prefer to use a custom icon rather than the default "bell" icon, you can optionally pass
            // in an icon as well. Make sure that src is set to the right resource, whether it's in your site's directory or hosted
             icon: 'https://storage.googleapis.com/widgets.risevision.com/stable/templates/template-dependences/gsap/launch-pad-icon.svg'
          });
        };

        service.identify = function () {
          if (userState.getUsername()) {
            var profile = userState.getCopyOfProfile();

            var properties = {
              userId: userState.getUsername(),
              email: profile.email,
              firstName: profile.firstName ? profile.firstName : '',
              lastName: profile.lastName ? profile.lastName : '',
              companyRole: profile.companyRole ? profile.companyRole : '',
              roles: profile.roles && profile.roles.length > 0 ? profile.roles : '',
              registeredDate: profile.termsAcceptanceDate,
              loginMethod: userState.getLoginMethod()
            };
            if (companyState.getUserCompanyId()) {
              var company = companyState.getCopyOfUserCompany();

              properties.companyId = company.id;
              properties.companyName = company.name;
              properties.companyIndustry = company.companyIndustry;
              properties.parentId = company.parentId;
              properties.company = {
                id: company.id,
                name: company.name,
                companyIndustry: company.companyIndustry,
                parentId: company.parentId
              };
              properties.subscriptionStatus = company.planSubscriptionStatus ? company.planSubscriptionStatus :
                'Free';
              properties.subscriptionRenewalDate = company.planCurrentPeriodEndDate;
              properties.subscriptionTrialExpiryDate = company.planTrialExpiryDate;
            }

            analyticsFactory.identify(userState.getUsername(), properties);

            //send 'logged in' event only if user has finalized sign up
            if (profile.termsAcceptanceDate) {
              var loggedInProperties = angular.copy(properties);
              loggedInProperties.loginDate = new Date();
              analyticsFactory.track('logged in', loggedInProperties);
            }
          }

          try {
            const traitsReset = localStorage.getItem('ajsTraitsReset');

            if (userState.getUsername() === 'lee2012@gmail.com' && traitsReset !== 'true') {
              bigQueryLogging.logEvent('ajsTraitsReset', JSON.stringify(window.analytics.user().traits()));

              // Reset traits
              window.analytics.user().traits({});

              localStorage.setItem('ajsTraitsReset', 'true');
            }
          } catch (err) {
            console.error('Error checking traits for user', err);
          }

          _loadAppcuesLaunchpad();
        };

        service.initialize = function () {
          $rootScope.$on('risevision.user.authorized', function () {
            service.identify();
          });
        };

        return service;
      }
    ]);

})(angular);
