'use strict';

angular.module('risevision.apps.services')
  .factory('companyAssetsFactory', ['$rootScope', '$q', 'CachedRequest', 'schedule', 'presentation',
    function ($rootScope, $q, CachedRequest, schedule, presentation) {
      var factory = {};

      var scheduleSearch = {
        sortBy: 'changeDate',
        reverse: true,
        count: 1
      };

      var scheduleListRequest = new CachedRequest(schedule.list.bind(schedule), scheduleSearch);
      var presentationListRequest = new CachedRequest(presentation.list.bind(presentation), scheduleSearch);

      var addScheduleListener, addScheduleCompleted, addPresentationListener, addPresentationCompleted;

      var _sendUpdateEvent = function () {
        $rootScope.$broadcast('companyAssetsUpdated');
      };

      var _clearScheduleListener = function () {
        if (addScheduleListener) {
          addScheduleListener();
          addScheduleListener = null;
        }
      };

      var _clearPresentationListener = function () {
        if (addPresentationListener) {
          addPresentationListener();
          addPresentationListener = null;
        }
      };

      var _addScheduleListener = function () {
        if (!addScheduleListener) {
          addScheduleListener = $rootScope.$on('scheduleCreated', function (event) {
            addScheduleCompleted = true;

            _clearScheduleListener();

            _sendUpdateEvent();
          });
        }
      };

      var _addPresentationListener = function () {
        if (!addPresentationListener) {
          addPresentationListener = $rootScope.$on('presentationCreated', function (event) {
            addPresentationCompleted = true;

            _clearPresentationListener();

            _sendUpdateEvent();
          });
        }
      };

      factory.hasSchedules = function () {
        if (addScheduleCompleted) {
          return $q.resolve(true);
        }

        return scheduleListRequest.execute().then(function (resp) {
          addScheduleCompleted = !!(resp && resp.items && resp.items.length > 0);

          if (!addScheduleCompleted) {
            _addScheduleListener();
          }

          return addScheduleCompleted;
        });
      };

      factory.hasPresentations = function () {
        if (addPresentationCompleted) {
          return $q.resolve(true);
        }

        return presentationListRequest.execute().then(function (resp) {
          addPresentationCompleted = !!(resp && resp.items && resp.items.length > 0);

          if (!addPresentationCompleted) {
            _addPresentationListener();
          }

          return addPresentationCompleted;
        });
      };

      $rootScope.$on('risevision.company.selectedCompanyChanged', function () {
        scheduleListRequest.reset();
        presentationListRequest.reset();
        addScheduleCompleted = undefined;
        addPresentationCompleted = undefined;
      });

      return factory;
    }
  ]);
