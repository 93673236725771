'use strict';

angular.module('risevision.common.components.logging')
  .factory('bigQueryLogging', ['externalLogging', 'userState', 'companyState',
    function (externalLogging, userState, companyState) {
      var factory = {};

      factory.logEvent = function (eventName, eventDetails, eventValue,
        username, companyId) {
        return externalLogging.logEvent(eventName, eventDetails, eventValue,
          username || userState.getUsername(), companyId || companyState.getSelectedCompanyId()
        );
      };

      return factory;
    }
  ]);
