'use strict';

angular.module('risevision.common.components.scrolling-list')
  .service('processErrorCode', ['STORAGE_CLIENT_ERROR', 'apiUtils',
    function (STORAGE_CLIENT_ERROR, apiUtils) {
      var actionsMap = {
        get: 'loaded',
        load: 'loaded',
        add: 'added',
        update: 'updated',
        delete: 'deleted',
        publish: 'published',
        restore: 'restored',
        move: 'moved',
        rename: 'renamed',
        upload: 'uploaded',
        restart: 'restarted',
        reboot: 'rebooted'
      };

      var _getPrefix = function (itemName, action, errorCodes) {
        var messagePrefix ='Something went wrong. ';
        var actionName = actionsMap[action];

        if (itemName && actionName) {
          itemName = itemName.toLowerCase();
          if (errorCodes) {
            if (errorCodes.length === 1) {
              itemName = itemName.replace(/s$/, '');
            }
            messagePrefix = errorCodes.length + ' ' + itemName + ' could not be ' + actionName + '. ';
          } else {
            messagePrefix = 'The ' + itemName + ' could not be ' + actionName + '. ';
          }
        }

        return messagePrefix;
      };

      return function (itemName, action, e, errorCodes) {
        var messagePrefix = _getPrefix(itemName, action, errorCodes);
        var tryAgain = messagePrefix + 'Please try again.';
        var tryAgainReload = messagePrefix + 'Please reload this page and try again.';
        var permissionRequired = 'You don’t have permission to do this. Please contact your system administrator.';
        var checkConnection = messagePrefix + 'Please check your connection, or proxy and firewall settings, and try again.';

        e = e || itemName;
        var error = apiUtils.getError(e);
        var errorString = error.message ? (messagePrefix + error.message) : tryAgain;

        // Attempt to internationalize Storage error
        var msg = action && STORAGE_CLIENT_ERROR[action] ?
          STORAGE_CLIENT_ERROR[action][error.message] :
          STORAGE_CLIENT_ERROR[error.message];
        if (msg) {
          errorString = msg;
        }

        if (!e) {
          return errorString;
        } else if (e.status === 400) {
          if (errorString.indexOf('is not editable') >= 0) {
            return errorString;
          } else if (errorString.indexOf('is required') >= 0) {
            return errorString;
          } else {
            return errorString;
          }
        } else if (e.status === 401) {
          return tryAgainReload;
        } else if (e.status === 403) {
          if (errorString.indexOf('User is not allowed access') >= 0) {
            return permissionRequired;
          } else if (errorString.indexOf('User does not have the necessary rights') >= 0) {
            return permissionRequired;
          } else if (errorString.indexOf('Premium Template requires Purchase') >= 0) {
            return permissionRequired;
          } else if (errorString.indexOf('Storage requires active subscription') >= 0) {
            return permissionRequired;
          } else if (errorString.indexOf('Not allowed access') >= 0) {
            return permissionRequired;
          } else {
            return errorString;
          }
        } else if (e.status === 404) {
          return tryAgain;
        } else if (e.status === 409) {
          if (itemName === 'Presentations' && action === 'delete' && errorCodes && errorCodes.length > 1) {
            var otherErrors = _.filter(errorCodes, function(code) {
              return code !== 409;
            });
            return messagePrefix + (otherErrors.length ? 'One or more of these ' : 'These ') +
              'presentations are embedded in other presentations. To delete them, ' +
              'they must be removed from the presentations they are being used in.';
          }
          return errorString;
        } else if (e.status === 500 || e.status === 503) {
          return tryAgain;
        } else if (e.status === -1 || error.code === -1 || error.code === 0) {
          return checkConnection;
        } else {
          return errorString;
        }
      };
    }
  ]);
