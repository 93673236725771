'use strict';

angular.module('risevision.storage.services')
  .factory('downloadBlob', ['$window',
    function ($window) {
      return function (blob, fileName) {
        var a = $window.document.createElement('a');
        a.href = $window.URL.createObjectURL(blob);
        a.download = fileName; // Set the file name.
        a.style.display = 'none';
        $window.document.body.appendChild(a);
        a.click();
        $window.document.body.removeChild(a);
      };
    }
  ]);
