'use strict';

/* Filters */
angular.module('risevision.storage.filters')
  .filter('fileSizeFilter', function () {
    return function (size) {
      var sizeString = '';
      if (size === 0) {
        return '0 bytes';
      }

      if (!size) {
        return '';
      }

      if (size < 1000) {
        sizeString = size + ' bytes';
      } else if (size > 1024 && size < Math.pow(1024, 2)) {
        sizeString = Math.floor(size / (1024 / 10)) / 10.0 + ' KB';
      } else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) {
        sizeString = Math.floor(size / (Math.pow(1024, 2) / 10)) / 10.0 +
          ' MB';
      } else if (size >= Math.pow(1024, 3)) {
        sizeString = Math.floor(size / (Math.pow(1024, 3) / 10)) / 10.0 +
          ' GB';
      }

      return sizeString;
    };
  })
  .filter('groupBy', function () {
    return function (items, groupedBy) {
      if (items) {
        var finalItems = [],
          thisGroup;
        for (var i = 0; i < items.length; i++) {
          if (!thisGroup) {
            thisGroup = [];
          }
          thisGroup.push(items[i]);
          if (((i + 1) % groupedBy) === 0) {
            finalItems.push(thisGroup);
            thisGroup = null;
          }
        }
        if (thisGroup) {
          finalItems.push(thisGroup);
        }
        return finalItems;
      }
    };
  })
  .filter('trashItemFilter', [function () {
    return function (itemName) {
      var trash = '--TRASH--/';

      if (itemName && itemName.indexOf(trash) === 0) {
        return itemName.substr(trash.length);
      } else {
        return itemName;
      }
    };
  }]);
