'use strict';

angular.module('risevision.apps.services')
  .service('proxyFileDownloader', ['fileDownloader', 'downloadBlob',
    function (fileDownloader, downloadBlob) {
      return function(url, fileName) {
        var proxyUrl = 'https://services2.risevision.com/proxy/' + url;
        fileDownloader(proxyUrl, '')
          .then(function(file) {
            downloadBlob(file, fileName);
          });
      };

    }
  ]);
